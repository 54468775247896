<template>
  <div>
    <div>
      <div>
      <b-modal
        ref="my-modal"
        hide-footer
        size="lg"
        title="Bienvenido a CVTAS"
      >
        <div class="d-block text-center">
          <p  class="font-weight-bolder text-center  font-size-p">
          El Centro Virtual del Tiempo Atmosférico Severo es un producto de los Servicios Meteorológicos de la Región</p>
          <img src="media/logos/loginBanner3.png" alt="" style="width: 100%">
        </div>
        <b-button
          class="mt-3"
          variant="outline-primary"
          block
          @click="hideModal"
        >
          Aceptar
        </b-button>
      </b-modal>
    </div>
      <iframe src="/maps/map.html?mapType=nowcasting"
        style="position: relative; height: 91vh; width: 100%;" scrolling="no" frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
// import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
     
    };
  },
  components: {
    // KTCodePreview
  },
  mounted() {
    console.log(this.$route.query.login);
    if(this.$route.query.login === 'true'){
      setTimeout(() => {
        this.showModal();
        setTimeout(() => {
          this.hideModal();
          let query1 = Object.assign({}, this.$route.query);
          delete query1.login;
          this.$router.replace({ query1 });
        }, 3000);
      }, 1000);      
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Nowcast", route: "nowcast" },
      { title: "Nowcast" }
    ]);
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    } 
  }
};
</script>
